import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Thanks = () => {
  const data = useStaticQuery(graphql`
    query {
      thanks: file(relativePath: { eq: "thanks.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Thank you" />
      <div className="p-5 text-center d-flex flex-column align-items-center">
        <Img fluid={data?.thanks?.childImageSharp?.fluid} style={{ width: '300px' }} />
        <h1 className="mt-3">Thank you for your interest!</h1>
        <h5 className="font-weight-normal">
          Please check your email for further instructions.
        </h5>
        <p className="mb-4">Having a problem? <a target="_blank" rel="noopener noreferrer" href="mailto:info@baubo.care">Contact Us</a>.</p>
        <Link to="/">Go back to the homepage</Link>
      </div>
    </Layout>
  );
}

export default Thanks
